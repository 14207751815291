import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PaymentManagementService {

  constructor(public http: HttpClient) { }
  getData(params) {
    return this.http.post(`${environment.apiUrl}get-payments?${params}`, '');
  }

  exportPaymentData(params?: any) {
    const timezone = moment.tz.guess(); // Get the local timezone
    const apiUrl = `${environment.apiUrl}export-payments-list?timezone=${timezone}`;
  
    let requestUrl = params ? `${apiUrl}&${params}` : apiUrl;
  
    this.http.get(requestUrl, {
      responseType: 'arraybuffer' // Expecting an array buffer response for file download
    }).subscribe(response => this.downLoadFile(response, "application/ms-excel", 'Transactions.xlsx'));
  }
  
  downLoadFile(data: any, type: string, filename: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a'); // Create an anchor element
    a.href = url;
    a.download = filename; // Set the file name
    document.body.appendChild(a); // Append it to the DOM
    a.click(); // Trigger download
    document.body.removeChild(a); // Clean up by removing the anchor element
    window.URL.revokeObjectURL(url); // Revoke the object URL to free memory
  }
}

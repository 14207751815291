import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  public deactivateInfo = new Subject<any>();
  constructor(public http: HttpClient) { }
  getData(params) {
    return this.http.post(`${environment.apiUrl}get-user?${params}`, '');
  }
  getUserDetails(userId: number) {
    return this.http.get(`${environment.apiUrl}get-user-profile-details?user_id=${userId}`);
  }
  getSubOwners(params) {
    return this.http.get(`${environment.apiUrl}get-sub-owner-list?${params}`);
  }
  deactiveAccount(payload) {
    return this.http.post(`${environment.apiUrl}get-user?user_id=${payload.userId}&is_deactivate=${payload.is_deactivate}&deactivated_reason=${payload.deactivated_reason}`, '');

  }
  updateProfile(params) {
    return this.http.post(`${environment.apiUrl}edit-profile?${params}`, '');

  }
  searchTable(params) {
    return this.http.post(`${environment.apiUrl}get-user?${params}`, '');
  }

  updateAddress(params) {
    return this.http.post(`${environment.apiUrl}edit-address-details?${params}`, '');
  }

  deleteUser(id) {
    return this.http.post(`${environment.apiUrl}delete-user-account?user_id=${id}`, '');
  }

  changeUserPassword(requestParams) {
    return this.http.post(`${environment.apiUrl}reset-user-password?user_id=${requestParams.id}&new_password=${requestParams.newPassword}`, '');
  }

  getDataExport(params?: any) {
    const timezone = moment.tz.guess(); // Get the local timezone
    const apiUrl = `${environment.apiUrl}export-user-details-list?timezone=${timezone}`;
  
    let requestUrl = params ? `${apiUrl}&${params}` : apiUrl;
  
    this.http.get(requestUrl, {
      responseType: 'arraybuffer' // Expecting an array buffer response for file download
    }).subscribe(response => this.downLoadFile(response, "application/ms-excel", 'User management.xlsx'));
  }

  getDataExportMember(params?: any) {
    const timezone = moment.tz.guess(); // Get the local timezone
    const apiUrl = `${environment.apiAdminUrl}export-guest-user-list?timezone=${timezone}`;
  
    let requestUrl = params ? `${apiUrl}&${params}` : apiUrl;
  
    this.http.get(requestUrl, {
      responseType: 'arraybuffer' // Expecting an array buffer response for file download
    }).subscribe(response => this.downLoadFile(response, "application/ms-excel", 'GuestBook.xlsx'));
  }
  
  downLoadFile(data: any, type: string, filename: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a'); // Create an anchor element
    a.href = url;
    a.download = filename; // Set the file name
    document.body.appendChild(a); // Append it to the DOM
    a.click(); // Trigger download
    document.body.removeChild(a); // Clean up by removing the anchor element
    window.URL.revokeObjectURL(url); // Revoke the object URL to free memory
  }
}

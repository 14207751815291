import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FilterData } from '../app-interface';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class AgentManagementService {

  constructor(public http: HttpClient) { }

  getData(params: FilterData) {
    return this.http.get(`${environment.apiAdminUrl}get-all-vendors?${params}`);
  }

  getAgentDetails(userId: number) {
    return this.http.get(`${environment.apiAdminUrl}get-all-vendors?user_id=${userId}`);
  }

  updateAgentDetailsById(params,id) {
    return this.http.post(`${environment.apiAdminUrl}edit-vendor/${id}?${params}`, '');

  }

  unassignQrCod(code: Array<number>) {
    const payload = code.toString();
    const params = new HttpParams();
    params.append(`qr_code_id`, payload);
    return this.http.post(`${environment.apiAdminUrl}assign-vendor?qr_code_id=${payload}`, null);
  }

  assignQrCodeToAgent(code: Array<number>,id) {
    const payload = code.toString();
    const params = new HttpParams();
    params.append(`qr_code_id`, payload);
    // if(remAssign && remAssign === true){
    //   return this.http.post(`${environment.apiAdminUrl}assign-vendor?qr_code_id=${payload}&remember_me=1`, null);
    // } else{
    return this.http.post(`${environment.apiAdminUrl}assign-vendor?qr_code_id=${payload}&vendor_id=${id}`, null);
    // }
  }

  assignQrCod(code: Array<number>,id,remAssign) {
    const payload = code.toString();
    const params = new HttpParams();
    params.append(`qr_code_id`, payload);
    if(remAssign && remAssign === true){
      return this.http.post(`${environment.apiAdminUrl}assign-vendor?qr_code_id=${payload}&remember_me=1`, null);
    } else{
    return this.http.post(`${environment.apiAdminUrl}assign-vendor?qr_code_id=${payload}&vendor_id=${id}`, null);
    }
  }


  addAgent(params) {
    return this.http.post(`${environment.apiAdminUrl}add-vendor?${params}`, '');
  }

  // exportSalesReport(params,vendor?) {
  //   if(params!==undefined && vendor?.status !== 'vendor'){
  //     return this.http.get(`${environment.apiAdminUrl}export-sales-report?${params}&timezone=${moment.tz.guess()}`);
  //   }else{
  //     return this.http.get(`${environment.apiAdminUrl}export-sales-report?timezone=${moment.tz.guess()}`);
  //   }
  // }

  exportSalesReport(params?: any, vendor?) {
    const timezone = moment.tz.guess(); // Get the local timezone
    const apiUrl = `${environment.apiAdminUrl}export-sales-report?timezone=${timezone}`;
  
    let requestUrl = (params&& vendor?.status !== 'vendor') ? `${apiUrl}&${params}` : apiUrl;
  
    this.http.get(requestUrl, {
      responseType: 'arraybuffer' // Expecting an array buffer response for file download
    }).subscribe(response => this.downLoadFile(response, "application/ms-excel", 'Sales tracking.xlsx'));
  }
  
  downLoadFile(data: any, type: string, filename: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a'); // Create an anchor element
    a.href = url;
    a.download = filename; // Set the file name
    document.body.appendChild(a); // Append it to the DOM
    a.click(); // Trigger download
    document.body.removeChild(a); // Clean up by removing the anchor element
    window.URL.revokeObjectURL(url); // Revoke the object URL to free memory
  }

  deactiveAccount(payload) {
    return this.http.post(`${environment.apiUrl}get-user?user_id=${payload.userId}&is_deactivate=${payload.is_deactivate}&deactivated_reason=${payload.deactivated_reason}`, '');

  }

  customSalesFilter(params) {
    return this.http.get(`${environment.apiUrl}vendor/get-commission-amount?${params}&timezone=${moment.tz.guess()}`);
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FilterData } from '../app-interface';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InformationManagementService {

  constructor(public http: HttpClient) { }

  getData(params: FilterData) {
    return this.http.get(`${environment.apiUrl}view_member_information?${params}`);
  }

  getinformationDetails(id: number) {
    return this.http.get(`${environment.apiUrl}view_member_information_data?id=${id}`);
  }

  updateInformationDetailsById(params, id: number, type: string) {
    const infoparams = new HttpParams().set(`id`, id).set(`${type}`, params);
    return this.http.put(`${environment.apiUrl}update_member_information/{id}?${infoparams}`, null);
  }

  deleteMedia(id) {
    return this.http.delete(`${environment.apiUrl}guestbook/delete-media?id=${id}`);
  }

  deactiveAccount(payload) {
    return this.http.post(`${environment.apiUrl}update_member_status?id=${payload.userId}&status=${payload.is_deactivate}&deactivated_reason=${payload.deactivated_reason}`, '');
  }

  exportInformation(params?: any) {
    const timezone = moment.tz.guess(); // Get the local timezone
    const apiUrl = `${environment.apiUrl}export-member-information-list?timezone=${timezone}`;
  
    let requestUrl = params ? `${apiUrl}&${params}` : apiUrl;
  
    this.http.get(requestUrl, {
      responseType: 'arraybuffer' // Expecting an array buffer response for file download
    }).subscribe(response => this.downLoadFile(response, "application/ms-excel", 'Information management.xlsx'));
  }
  
  downLoadFile(data: any, type: string, filename: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a'); // Create an anchor element
    a.href = url;
    a.download = filename; // Set the file name
    document.body.appendChild(a); // Append it to the DOM
    a.click(); // Trigger download
    document.body.removeChild(a); // Clean up by removing the anchor element
    window.URL.revokeObjectURL(url); // Revoke the object URL to free memory
  }

}

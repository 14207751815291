

export const environment = {
    production: false,
    memorialUrl: 'https://eternallyme.dev.frontend.urmbrme.com/#/view-information/',
    apiUrl: 'https://eternallyme.dev.backend.urmbrme.com/api/v1/',
    apiAdminUrl: 'https://eternallyme.dev.backend.urmbrme.com/api/v1/admin/',
    tinyMceKey: '85x45zydsg3p8hk5rk2vrtjee5zba3zj0vlfxpydkvq0htvr'
    
};

